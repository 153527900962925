import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ChangeEvent, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion } from "../../types/survey";
import { ScreeningQuestionForm } from "../ScreeningQuestionForm/ScreeningQuestionForm";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./ScreeningQuestionList.scss";

interface ScreeningQuestionListProps {
  questions?: CustomQuestion[];
  refetchQuestions?: () => void;
  setSuccessMsg: (msg: string) => void;
}

export const ScreeningQuestionList = ({
  questions,
  refetchQuestions,
  setSuccessMsg,
}: ScreeningQuestionListProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };
  return (
    <div className="ScreeningQuestionList">
      {questions?.map((question, index) => {
        const panelId = `panel-${index}`;
        const isExpanded = expandedPanel === panelId;

        return (
          <Accordion
            key={question.text}
            expanded={isExpanded}
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              expandIcon={
                <Button icon color="secondary">
                  <Icon type={IconTypes.NavArrow} />
                </Button>
              }
              aria-controls={`question${index}-content`}
              id={`question${index}-header`}
            >
              <div className="ScreeningQuestionList__header">
                <Typography desktop="body1" weight="bold">
                  {t("addQuestions.question.title") + " " + (index + 1)}
                  {!isExpanded && ":"}
                </Typography>
                {!isExpanded && (
                  <Typography desktop="body1">
                    <span style={{ marginRight: "0.5rem" }}></span>
                    {question.text}
                  </Typography>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ScreeningQuestionForm
                questions={questions}
                questionData={question}
                questionIndex={index}
                refetchQuestions={refetchQuestions}
                isInAccordion
                setSuccessMsg={setSuccessMsg}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
