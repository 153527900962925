import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestionsResponse } from "../../types/survey";
import { ScreeningQuestionForm } from "../ScreeningQuestionForm/ScreeningQuestionForm";
import { ScreeningQuestionList } from "../ScreeningQuestionList/ScreeningQuestionList";
import { Button } from "../ui/Button/Button";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Typography } from "../ui/Typography/Typography";
import "./ScreeningQuestionOverview.scss";

export const ScreeningQuestionOverview = () => {
  const { t } = useTranslation("surveyLaunchPage");
  const surveyService = new SurveyService();
  const [successMsg, setSuccessMsg] = useState("");
  const [addNewQuestion, setAddNewQuestion] = useState(false);

  const { data: questionsData, refetch: refetchQuestions } = useQuery<
    CustomQuestionsResponse,
    AxiosError
  >(["customQuestions"], () => surveyService.getCustomQuestions());

  return (
    <div className="ScreeningQuestionOverview">
      <Typography
        tagVariant="p"
        desktop="body1"
        className="ScreeningQuestionOverview__subtitle"
      >
        {t("addQuestions.subtitle")}
      </Typography>
      {questionsData?.questions && questionsData.questions.length > 0 && (
        <Typography
          tagVariant="h3"
          desktop="h2"
          className="ScreeningQuestionOverview__title"
        >
          {t("addQuestions.addedQuestions.title")}
        </Typography>
      )}
      <ScreeningQuestionList
        questions={questionsData?.questions}
        refetchQuestions={refetchQuestions}
        setSuccessMsg={setSuccessMsg}
      />

      {addNewQuestion && (
        <ScreeningQuestionForm
          questions={questionsData?.questions}
          refetchQuestions={refetchQuestions}
          setSuccessMsg={setSuccessMsg}
          closeForm={() => setAddNewQuestion(false)}
        />
      )}

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => setAddNewQuestion(true)}
        className="ScreeningQuestionOverview__action"
      >
        {t("actions.addQuestion")}
      </Button>

      <Snackbar
        open={!!successMsg}
        message={successMsg}
        onClose={() => setSuccessMsg("")}
      />
    </div>
  );
};
